import React, { useState, useEffect } from "react";
// import "boxicons/css/boxicons.min.css"; // Import Boxicons icons
import css from "./CookieConsent.module.css"; // Import the CSS module
import cookiesicon from './cookiesIcon.svg'
const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            const consent = localStorage.getItem("cookieConsent");
            if (!consent) {
                setIsVisible(true);
            }
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    const handleAccept = () => {
        localStorage.setItem("cookieConsent", "accepted");
        setIsVisible(false);
        console.log("Cookies accepted");
        // Here you can add any logic for enabling cookies or tracking
    };

    const handleDecline = () => {
        localStorage.setItem("cookieConsent", "declined");
        setIsVisible(false);
        console.log("Cookies declined");
        // Here you can add any logic for disabling cookies or tracking
    };


    return (
        isVisible && (
            <div className={css.wrapper} data-nosnippet>
                <header className={css.header}>
                    <img src={cookiesicon} className={css.h2}></img>
                    <h4 className={css.h2}>Cookies Consent</h4>
                </header>
                <div className={css.data}>
                        We use cookies to improve your browsing experience and personalise content. By continuing to use this website, you consent to our use of cookies.
                        {/* Functional cookies will be consistently enabled. */}
                </div>
                <div className={css.buttons}>
                    <button className={css.acceptButton} id={css.acceptBtn} onClick={handleAccept}>
                        Accept
                    </button>
                    {/* <button className={css.declineButton} id={css.declineBtn} onClick={handleDecline}>
                        Decline
                    </button> */}
                </div>
            </div>
        )
    );
};

export default CookieConsent;
